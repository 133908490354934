import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Layout from '/src/components/layout';
import ContentSection from '/src/components/atoms/ContentSection';
import { Row, Col } from '/src/components/atoms/GridRow';
import Spinner from '/src/components/atoms/Spinner';
import SEO from '/src/components/atoms/Seo';
import ProductDetail from '/src/components/organisms/ProductDetail';

const productQuery = gql`
  query($id: String!) {
    stripeProduct(id: $id) {
      name
      description
      id
      skus {
        id
        attributes {
          name
        }
        price
        image
      }
      metadata {
        fb_event
      }
    }
  }
`;

const Product = ({ pageContext }) => {
  const { id, title } = pageContext;
  const { loading, error, data } = useQuery(productQuery, {
    variables: { id },
  });

  const product = data ? data.stripeProduct : {};

  return (
    <Layout hideHeader>
      <SEO title={`${title} | Shop`} />
      <ContentSection>
        <h1 className="heading3">{title}</h1>
      </ContentSection>
      <ContentSection light>
        <Row center vertGutter>
          <Col width={6}>
            {!error ? (
              !loading ? (
                <ProductDetail product={product} />
              ) : (
                <Spinner />
              )
            ) : (
              <p>Error loading product</p>
            )}
          </Col>
        </Row>
      </ContentSection>
    </Layout>
  );
};

export default Product;
