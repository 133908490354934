import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { omit, path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withState, withProps, withHandlers } from 'recompose';

import { actions } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import FormGroup from '/src/components/molecules/FormGroup';
import Button from '/src/components/atoms/Button';

import './index.scss';

const SkuCard = ({ sku, price, addToCart, quantity, handleQuantityChange }) => (
  <div className="SkuCard">
    <Row>
      <Col width={3} className="SkuCard__image">
        {sku.image && <img className="img-responsive" src={sku.image} alt="" />}
      </Col>
      <Col width={6} className="SkuCard__details ml-auto">
        <h3 className="SkuCard__title">{sku.attributes.name}</h3>
        {sku.description && (
          <p className="SkuCard__description">{sku.description}</p>
        )}
        <p className="SkuCard__price tr">{`$${price}`}</p>
      </Col>
      <Col width={3} className="SkuCard__action">
        <FormGroup
          id={`quantity__${sku.id}`}
          inline
          label="Quantity"
          value={quantity}
          className="w-100"
          inputProps={{
            type: 'number',
            min: 0,
            onChange: handleQuantityChange,
          }}
        />
        <Button
          className="w-100"
          component={Link}
          to="/shop/cart"
          onClick={addToCart}
        >
          Add to Cart
        </Button>
      </Col>
    </Row>
  </div>
);

const skuType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
}).isRequired;

SkuCard.propTypes = {
  sku: skuType,
  shippingSku: skuType,
  // generated
  price: PropTypes.string.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addItem: actions.addItem,
};

const priceSelector = createSelector(
  path(['sku', 'price']),
  price => (price * 0.01).toFixed(2)
);

const propSelectors = createStructuredSelector({
  price: priceSelector,
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withState('quantity', 'setQuantity', 1),
  withProps(propSelectors),
  withHandlers({
    handleQuantityChange: ({ setQuantity }) => event =>
      setQuantity(event.target.value),
    addToCart: ({ sku, shippingSku, product, quantity, addItem }) => () =>
      addItem({
        ...sku,
        quantity,
        product: omit(['skus'], product), // prevent circular reference
        shippingSku,
      }),
  })
)(SkuCard);
