import React from 'react';
import { compose as Rcompose, find, path, prop, not } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { withProps } from 'recompose';

import { selectors } from '/src/store/cart';
import { newlinesToDivs } from '/src/utils/string';
import SkuCard from '/src/components/molecules/SkuCard';

const ProductDetail = ({ product, cartItems, shippingSku }) => (
  <React.Fragment>
    {product.description && (
      <p className="space-top">{newlinesToDivs(product.description)}</p>
    )}
    <div className="space-top">
      {cartItems.map(sku => (
        <SkuCard
          key={sku.id}
          sku={sku}
          product={product}
          shippingSku={shippingSku}
        />
      ))}
    </div>
    <div className="space-top space-bottom">
      {product.metadata.fb_event && (
        <div className="space-top space-bottom">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={product.metadata.fb_event}
          >
            RSVP on Facebook
          </a>
        </div>
      )}
    </div>
  </React.Fragment>
);

const skusSelector = createSelector(
  prop('product'),
  path(['skus'])
);

const cartableSkusSelector = createSelector(
  skusSelector,
  skus => skus.filter(Rcompose(not, selectors.isShippingSku))
);

const shippingSkuSelector = createSelector(
  skusSelector,
  find(selectors.isShippingSku)
);

const propSelectors = createStructuredSelector({
  cartItems: cartableSkusSelector,
  shippingSku: shippingSkuSelector,
});

export default withProps(propSelectors)(ProductDetail);
